import { useCallback, useEffect, useRef } from 'react';
import { captureException } from '@sentry/react-native';
import { useRoute } from '@react-navigation/native';
import { parseBotPromptMessage, quickReplyIds } from 'src/constants/messages';
import { useDispatchMapper } from 'src/hooks/actionHooks';
import useConnectTwilioChannel from 'src/hooks/useConnectTwilioChannel';
import { useNotification } from 'src/hooks/useNotification';
import useProfile from 'src/hooks/useProfile';
import { getMyDevices, reportDeviceService, submitDeviceConfiguration } from 'src/lib/api/connectWise';
import { setActiveTicketAction } from 'src/redux/chat/chatActions';
import { getLiveChatSummary, getMessengerSource, isElectron } from 'src/utils';
import { normalizeToGraphqlFragment, normalizeTicket } from 'src/utils/normalizer';
import { promptNotifPermission } from 'src/utils/desktop-notification';
import webchatIframe from 'src/utils/webchatIframe';
import useTranslation from 'src/hooks/useTranslation';
import useChatState from './useChatState';
import useMessageHandlers from './useMessageHandlers';
import { reactiveVarClient } from '@Thread-Magic/thread-service-utils';
import { MESSENGER_STATUS, THREAD_STATES } from 'src/constants';
import { CORE_THREAD_FIELDS } from 'src/graphql/constants';
import { CHAT_NEW_TICKET_STATES } from 'src/redux/chat/chatStateConstants';
import * as Sentry from '@sentry/react-native';
import { modifyMyThreadsCache } from 'src/graphql/cache';
import useChatMessages from './useChatMessages';
import { useTwilioClient } from '@Thread-Magic/chat-utils';

export const CUSTOMER_ENDED_CHAT_TXT =
  'User has left the live chat. You can still respond to this message but they may not respond as quickly.';

const useNewTicketFlow = ({ setInputDisabled }) => {
  const { userInfo, companyInfo } = useProfile();
  const { chatState, saveTicket, setTwilioChannel, setNewTicketCreationState } = useChatState();
  const saveActiveTicket = useDispatchMapper(setActiveTicketAction);
  const { addBotMessage, addMessage, addMyMessage, onTwilioMessage } = useMessageHandlers();
  const { connectChannel } = useConnectTwilioChannel();
  const { permission } = useNotification();
  const hasSentInitialMessages = useRef(false);
  const twilioChannelRef = useRef();
  const { translate, locale } = useTranslation();
  const route = useRoute();
  const { client } = useTwilioClient();
  const { twilioChannel } = chatState;
  const isLiveChat = !route.params?.isNewRequest;
  const botPromptMessages = companyInfo.botPromptMessages?.[locale];
  const {
    new_thread_greeting: greetingPrompt,
    selected_device_confirmation: deviceSelectPrompt,
    thread_created_confirmation: threadCreatedPrompt,
    choose_device: chooseDevicePrompt,
  } = botPromptMessages || {};
  const apolloClient = reactiveVarClient();

  const createNewTicketAndSendDeviceOptions = async (summary, attachmentsToBeSent) => {
    if (!summary && !attachmentsToBeSent) return;
    setInputDisabled(true);
    // create new thread
    const ticket = await createTicket({ summary, attachmentsToBeSent });

    if (!ticket) return;

    const response = await getMyDevices(1, {
      userId: userInfo?.id,
      role: userInfo?.role,
      companyId: companyInfo?.id,
    });
    // attach device to thread if contact has device(s) to choose
    const myDevices = response?.data;

    if (Array.isArray(myDevices) && myDevices?.length) {
      const values = myDevices.slice(0, 5).map((device) => ({
        label: device.name,
        value: device.id,
        device,
      }));
      values.push({
        label: translate('ticket.liveChat.devices.none'),
        value: null,
        device: null,
      });
      addMessage({
        type: 'text',
        isMine: false,
        message: chooseDevicePrompt || translate('bot.devices'),
        quickReplies: {
          id: quickReplyIds.SELECT_DEVICE,
          values,
        },
      });
    }
  };

  /**
   *
   * @param payload
   * @param extra - contains width and height information of attachment
   * @returns {Promise<void>|*}
   */
  const onSubmit = (payload, extra) => {
    const { attachment: { width, height } = {}, attachmentsToBeSent } = extra || {};
    if (payload || attachmentsToBeSent) {
      if (chatState.ticket?.systemId && chatState.twilioChannel) {
        const source = getMessengerSource();

        if (!userInfo?.fullname) {
          Sentry.captureMessage('Unknown username: useNewTicketFlow:onSubmit', {
            extra: { userInfo },
          });
        }

        return chatState.twilioChannel.sendMessage(payload, {
          contactId: userInfo?.contactId,
          companyId: companyInfo?.parentId,
          author: userInfo?.fullname || userInfo?.email,
          user_type: 'contact',
          is_internal: false,
          senderImageURL: userInfo.profilePicture?.path,
          source,
          ...(width &&
            height && {
              width,
              height,
            }),
        });
      }
      const validSummary = typeof payload === 'string' ? payload : 'Attachment';
      saveTicket({ summary: validSummary, attachmentsToBeSent });
      addMyMessage(payload, extra);
      if (attachmentsToBeSent?.length) {
        attachmentsToBeSent.forEach(({ message, ...rest }) => addMyMessage(message, rest));
      }
      createNewTicketAndSendDeviceOptions(validSummary, attachmentsToBeSent);
      return Promise.resolve();
    }
  };

  const handleTwilioNewMessages = useCallback((message) => {
    // don't show first message again, as it is already shown
    if (!message?.attributes?.initialMessage) {
      onTwilioMessage(message);
    }
  }, []);

  const sendInitialAttachmentMessages = async (twilioChannel) => {
    const { attachmentsToBeSent = [] } = chatState.ticket || {};
    attachmentsToBeSent.forEach(({ message, attachment }) => {
      const { width, height } = attachment || {};
      try {
        twilioChannel.sendMessage(message, {
          contactId: userInfo.contactId,
          companyId: companyInfo?.parentId,
          author: userInfo?.fullname || userInfo?.email,
          user_type: 'contact',
          is_internal: false,
          initialMessage: true,
          senderImageURL: userInfo.profilePicture?.path,
          ...(width &&
            height && {
              width,
              height,
            }),
        });
      } catch (err) {
        console.log(err);
        captureException(new Error(`Failed to send initial attachment messages: ${err.message}`));
      }
    });
  };

  const joinTwilioChannel = async (channelName, channelId) => {
    try {
      const connectedChannel = await connectChannel(channelName?.toString(), channelId);
      setTwilioChannel(connectedChannel);
      setInputDisabled(false);
    } catch (err) {
      console.log(err);
      addBotMessage(translate('bot.errorMessage') + err.message);
      captureException(
        new Error(`Failed to join newly created ticket twilio channel: ${err.message}`, {
          extra: {
            channelName,
            channelId,
          },
        }),
      );
      setInputDisabled(true);
    }
  };

  const attachDeviceToTicket = (device, ticketId) => {
    if (device?.id) {
      submitDeviceConfiguration({
        ticketId,
        payload: {
          id: device?.id,
          deviceIdentifier: device?.deviceIdentifier,
          info: device?._info,
        },
      }).catch((err) => console.log(err));
    }
  };

  const handleApolloCacheChange = (ticket) => {
    try {
      const normalizedFragmentData = normalizeToGraphqlFragment(ticket);
      // first create a fragment in memory
      apolloClient.writeFragment({
        fragment: CORE_THREAD_FIELDS(),
        data: normalizedFragmentData,
      });
      // store the fragment
      modifyMyThreadsCache({ threadToAdd: normalizedFragmentData });
    } catch (err) {
      captureException(new Error(`[GraphQL] Failed to add new thread to apollo cache ${err.message}`));
    }
  };

  const createTicket = async ({ summary, attachmentsToBeSent }) => {
    const threadSummary = summary || 'Attachment';
    const threadState = isLiveChat ? THREAD_STATES.LIVE : THREAD_STATES.ACTIVE; // "live" will trigger swarm mode
    const payload = {
      summary: isLiveChat ? getLiveChatSummary(threadSummary) : threadSummary, // Adds "Live Chat:" to the beginning of summary
      initialDescription: summary,
      board: 'support', // "support" flow is used for existing users
      state: threadState,
    };

    try {
      setNewTicketCreationState(CHAT_NEW_TICKET_STATES.CREATING);
      const response = await reportDeviceService({ payload });
      const ticket = response.ticket;

      if (!ticket) {
        throw new Error('Request succeeded with no ticket data');
      }

      const normalizedTicket = normalizeTicket(ticket);
      saveActiveTicket(normalizedTicket);

      const threadCreatedText =
        parseBotPromptMessage(threadCreatedPrompt, {
          replaceTxt: '${ticket_id}',
          actualTxt: normalizedTicket.number,
        }) || translate('bot.getTicketMessage', normalizedTicket.number);
      addBotMessage(threadCreatedText);

      if (permission === 'default') {
        addBotMessage(translate('bot.notificationPermission2'));
        if (isElectron()) {
          promptNotifPermission();
        } else {
          webchatIframe.requestNotificationPermission();
        }
      }
      saveTicket({ ...normalizedTicket, attachmentsToBeSent });
      handleApolloCacheChange({ ...ticket, state: threadState });

      return ticket;
    } catch (err) {
      if (err?.response?.data?.status === 400) {
        addBotMessage(err.response?.data?.message);
      } else {
        addBotMessage(translate('bot.contactSupport.newTicket') + err.message);
      }
      captureException(new Error(`Failed to create new ticket ${err.response?.data?.message || err.message}`));
    } finally {
      setInputDisabled(false);
      setNewTicketCreationState(CHAT_NEW_TICKET_STATES.FINISHED);
    }
  };

  const handleSelectDevice = async (reply) => {
    if (reply?.label) {
      const deviceSelectText = reply.device
        ? parseBotPromptMessage(deviceSelectPrompt, {
            replaceTxt: '${device_name}',
            actualTxt: reply.label,
          }) || translate('bot.getDeviceMessage', reply.label)
        : translate('ticket.liveChat.devices.none');

      addMyMessage(deviceSelectText);
    }
    attachDeviceToTicket(reply?.device, chatState.ticket?.id);
  };
  const quickReplyActions = {
    [quickReplyIds.SELECT_DEVICE]: handleSelectDevice,
  };

  const { messages, onQuickReply } = useChatMessages({ quickReplyActions, chatMessages: chatState.messages });

  const handleTwilioUnsubscribeAction = (channel) => {
    try {
      channel.sendMessage(CUSTOMER_ENDED_CHAT_TXT, {
        contactId: userInfo.contactId,
        companyId: companyInfo.parentId,
        author: companyInfo.botName || 'Thread',
        user_type: 'contact',
        is_internal: true,
        senderImageURL: userInfo.profilePicture?.path,
      });
    } catch (err) {
      captureException(new Error(`Failed to handleTwilioUnsubscribeAction: ${err.message}`));
    }
  };

  useEffect(() => {
    if (chatState.ticket?.channel) {
      joinTwilioChannel(chatState.ticket.channel, chatState.ticket.channelId);
    }
  }, [client, chatState.ticket?.channel]);

  useEffect(() => {
    if (!twilioChannel) return;
    twilioChannelRef.current = twilioChannel;
    twilioChannel.subscribeNewMessages(handleTwilioNewMessages);
    if (!hasSentInitialMessages.current) {
      sendInitialAttachmentMessages(twilioChannel);
      hasSentInitialMessages.current = true;
    }

    return () => {
      twilioChannel.unsubscribeNewMessages(handleTwilioNewMessages);
    };
  }, [twilioChannel]);

  useEffect(() => {
    return () => {
      if (twilioChannelRef.current) {
        handleTwilioUnsubscribeAction(twilioChannelRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!chatState.messages?.length) {
      const isOffline = companyInfo.availability?.status === false;
      if (!isOffline) {
        const greetingText =
          parseBotPromptMessage(greetingPrompt, {
            replaceTxt: '${company_name}',
            actualTxt: companyInfo.name,
          }) || translate('bot.getGreetingMessage', locale);

        if (companyInfo.messengerStatus === MESSENGER_STATUS.DISABLED) {
          addBotMessage(translate('bot.workspace.disabled'));
        } else {
          addBotMessage(greetingText);
        }
        return;
      }
      const customMessage =
        companyInfo.availability?.custom_message?.show && companyInfo.availability?.custom_message?.message;
      addBotMessage(customMessage || translate('bot.getOfflineMessage', locale));
      addBotMessage(translate('bot.getOfflineMessage2'));
    }
  }, []);

  return {
    messages,
    onQuickReply,
    onSubmit,
    hasMore: false,
  };
};

export default useNewTicketFlow;
